<template>
    <div class="page" v-if="ready">
        <ProfileMenu section="logout" />
        <div>
            <h1>登出系統</h1>
        </div>

        <div class="message">
            <p>您是否確定登出系統</p>
            <div class="control">
                <a @click="logout" class="button">是，現在登出</a>
                <router-link to="/course/" class="button">否</router-link>
            </div>
        </div>
    </div>
</template>
<script>
import ProfileMenu from './ProfileMenu'
export default {
    components: { ProfileMenu },
    data() {
        return {
            ready: false
        }
    },
    mounted() {
        const params = new FormData()
        params.append('token', this.Auth.getToken())
        this.$axios
            .post(process.env.VUE_APP_API + 'check-questionnaire' + process.env.VUE_APP_SERVER_SIDE_LANG, params)
            .then(response => {
                let r = response.data
                if (r.success !== true) {
                    this.$root.setQuestionnaire()
                }
                this.ready = true
            })
    },
    methods: {
        logout() {
            this.Auth.Logout()
            this.$root.inner = false
            this.$router.push('/')
        }
    }
}
</script>

<style scoped lang="scss">
@import 'Page.scss';
.message {
    max-width:380px;
    margin:auto;
    text-align:center;
    p {
        padding:60px 0px;
        font-size:18px;
    }
}
.control {
    display:flex;
    justify-content:center;
    column-gap:20px;
    a {
        min-width:40%;
    }
}
</style>
 